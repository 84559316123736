import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";

export default [
    {
        path: '/admin/communities',
        name: 'admin-communities',
        component: () => import('@/modules/Admin/Pages/Communities/CommunitiesPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.communities.title',
            breadcrumb: [
                { title: 'admin.communities.title', url: `/admin/communities`, active: true },
            ]
        },
    },
    {
        path: '/admin/communities/:uuid',
        name: 'admin-communities-edit',
        component: () => import('@/modules/Admin/Pages/Communities/EditCommunityPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.communities',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.communities', url: `/admin/communities`, active: false },
                {
                    title: () => {
                        const community = store.getters.routeElement
                        if (!community) {
                            return `${i18n.t('shared.add')}`
                        }
                        return `${i18n.t('datatable.button.edit')} ${community.name}`
                    },
                    active: true
                },
            ]
        },
    },
]
