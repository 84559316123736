import store from "@/modules/Shared/Store/store";

export default [
    {
        path: '/admin/client-users',
        name: 'admin-client-users',
        component: () => import('@/modules/Admin/Pages/ClientUsersPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.client-users.title',
            breadcrumb: [
                { title: 'admin.client-users.title', url: `/admin/client-users`, active: true }
            ]
        },
    },
    {
        path: '/admin/client-users/:uuid',
        name: 'admin-client-user-detail',
        component: () => import('@/modules/Admin/Pages/ClientUserDetailPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.client-users.detail',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.client-users.title', url: `/admin/client-users`, active: false },
                {
                    title: () => {
                        const user = store.getters.routeElement
                        return user.username
                    },
                    active: true
                },
            ]
        },
    },
]
