/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

import i18n from "@/i18n.js";

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },

  applicationLanguage: state => {
    return state.applicationLanguage;
  },
  applicationUuid: state => {
    return state.applicationUuid;
  },
  AppActiveUser: state => {
    return state.AppActiveUser;
  },
  resortUuid: state => {
    return state.resortUuid;
  },
  banner: state => {
    return state.banner;
  },
  timeOfYear: () => {
    let always = i18n.t("always")
    let winter = i18n.t("winter")
    let summer = i18n.t("summer")

    return [
      { name: always.charAt(0).toUpperCase() + always.slice(1), value: 'always' },
      { name: winter.charAt(0).toUpperCase() + winter.slice(1), value: 'winter' },
      { name: summer.charAt(0).toUpperCase() + summer.slice(1), value: 'summer' }
    ]
  },
  
  routeElement: state => state.routeElement,

  spotlioLanguages: state => {
    return state.spotlioLanguages
  },

  userSpotlioLanguages: state => {
    const managedLanguageIds = state.AppActiveUser.managed_language_ids
    return state.spotlioLanguages.filter((language) => managedLanguageIds.includes(language.id))
  }
}

export default getters
